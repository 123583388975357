import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import "../addedcss/blogv3.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
    maxHeight: 345,
    height: "100%",
    overflow: "scroll",
  },
  media: {
    height: 140,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "visible",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "50vw",
    margin: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    padding: theme.spacing(2, 4, 3),
    minHeight: "1vh",
    width: "50vw",
  },
}));
const MainBlog = ({
  title,
  author,
  date,
  content,
  image,
  post,
  handleOpenModal,
  setSelectedPost,
}) => {
  return (
    <div
      style={{
        background: "#ffffff",
        padding: "20px",
        boxShadow: "0px 0px 15px #e9e9e9",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        transition: "all 0.5s ease", // add this line
      }}
    >
      <img
        src={image}
        style={{
          width: "100%",
          maxHeight: "500px",
          objectFit: "cover",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      />
      <div
        style={{
          padding: "20px",
          textAlign: "left",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            color: "#3078ff",
            margin: "0 0 20px 0",
            fontWeight: "500",
          }}
        >
          Blog Post
        </p>
        <h2
          style={{
            fontSize: "32px",
            margin: "0 0 10px 0",
            fontWeight: "bold",
          }}
        >
          {title}
        </h2>
        <p
          style={{
            fontSize: "14px",
            color: "#9b9b9b",
            margin: "0 0 20px 0",
          }}
        >
          By {author} on {date}
        </p>
        <p
          style={{
            fontSize: "16px",
            color: "#4d4d4d",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </p>
        <Link to={`/blog/${post.slug}`}>
          <Button size="small" color="primary">
            Read More
          </Button>
        </Link>
      </div>
    </div>
  );
};

const Blog = ({
  title,
  author,
  date,
  content,
  image,
  post,
  handleOpenModal,
  setSelectedPost,
}) => {
  // adjust this number to your desired length
  const MAX_LENGTH = 30; // adjust this number to your desired length

  const truncatedContent =
    content.length > MAX_LENGTH
      ? content.substring(0, MAX_LENGTH) + "..."
      : content;

  return (
    <>
      <div
        style={{
          background: "#ffffff",
          padding: "15px",
          boxShadow: "0px 0px 15px #e9e9e9",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          width: "80%",
          margin: "15px",
        }}
      >
        <img
          src={image}
          style={{
            width: "100%",
            maxHeight: "500px",
            objectFit: "cover",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "0",
            borderBottomLeftRadius: "10px",
            marginRight: "20px",
          }}
        />
        <div
          style={{
            padding: "20px",
            textAlign: "left",
            flex: 1,
          }}
        >
          <p
            style={{
              fontSize: "12px",
              color: "#3078ff",
              margin: "0 0 20px 0",
              fontWeight: "500",
            }}
          >
            Blog Post
          </p>
          <h2
            style={{
              fontSize: "1.5vw",
              margin: "0 0 10px 0",
              fontWeight: "bold",
            }}
          >
            {title}
          </h2>
          <p
            style={{
              fontSize: "14px",
              color: "#9b9b9b",
              margin: "0 0 20px 0",
            }}
          >
            By {author} on {date}
          </p>
          <p
            style={{
              fontSize: "16px",
              color: "#4d4d4d",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: truncatedContent,
              }}
            />
          </p>
          <Link to={`/blogv3/${post.slug}`}>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                handleOpenModal(post);
              }}
            >
              Read More
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
const SecondaryBlog = ({
  title,
  author,
  date,
  image,
  content,
  post,
  handleOpenModal,
  setSelectedPost,
}) => (
  <div
    style={{
      background: "#f2f2f2",
      padding: "20px",
      boxShadow: "0px 0px 15px #e9e9e9",
      borderRadius: "10px",
      marginBottom: "20px",
      display: "flex",
      flexDirection: "row",
    }}
  >
    <img src={image} style={{ width: "50%", height: "auto" }} />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        textAlign: "left",
        paddingLeft: "20px",
        width: "70%",
      }}
    >
      <p
        style={{
          fontSize: "12px",
          color: "#3078ff",
          margin: "0 0 20px 0",
          fontWeight: "500",
        }}
      >
        Blog Post
      </p>
      <h3
        style={{
          fontSize: "24px",
          margin: "0 0 10px 0",
          fontWeight: "bold",
        }}
      >
        {title}
      </h3>
      <p
        style={{
          fontSize: "14px",
          color: "#9b9b9b",
          margin: "0 0 20px 0",
        }}
      >
        By {author} on {date}
      </p>
      <p
        style={{
          fontSize: "16px",
          color: "#4d4d4d",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </p>
      <Link to={`/blog/${post.slug}`}>
        <Button
          size="small"
          color="primary"
          style={{ width: "4vw", marginRight: 0 }}
        >
          Read More
        </Button>
      </Link>
    </div>
  </div>
);

const BlogsLayout = () => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#4A63A5",
          color: "#4A63A5",
          borderRadius: "180px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#4A63A5",
          color: "#4A63A5",
          borderRadius: "180px",
        }}
        onClick={onClick}
      />
    );
  }

  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedPost({});
    setOpenModal(false);
  };
  useEffect(() => {
    fetch(
      "https://public-api.wordpress.com/rest/v1.1/sites/thrthrtwhergtwrhwtrhwtrh.wordpress.com/posts/"
    )
      .then((res) => res.json())
      .then((data) => {
        setPosts(data.posts);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const mainPost = posts.slice(0, 1);
  const subPosts = posts.slice(1, 3);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet title="Blog | Genify" defer={false} />
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
          path: "blogPage",
        }}
        footerConfig={{
          style: "blog", //style1, style2
        }}
      >
        <div
          class="pt-13 pt-lg-24 pb-lg-24 bg-default-1"
          style={{ marginTop: "5%" }}
        >
          <section style={{ padding: "3em 10vw" }}>
            <h1
              style={{
                fontSize: "64px",
                lineHeight: "70px",
                fontWeight: "700",
                textAlign: "left",
                marginBottom: "30px",
              }}
            >
              The <span style={{ color: "#4A63A5" }}>Genify </span>Blog
            </h1>
            <div className="test-bg">
              {posts.map((post) => (
                <SecondaryBlog
                  image={post.featured_image}
                  title={post.title}
                  author={`${post.author["first_name"]}  ${post.author["last_name"]}`}
                  date={post.date}
                  content={post.excerpt}
                  post={post}
                  handleOpenModal={handleOpenModal}
                />
              ))}
              {/* {mainPost[0] && (
                <MainBlog
                  image={mainPost[0].featured_image}
                  title={mainPost[0].title}
                  author={`${mainPost[0].author["first_name"]}  ${mainPost[0].author["last_name"]}`}
                  date={mainPost[0].date}
                  content={mainPost[0].excerpt}
                  post={mainPost[0]}
                  handleOpenModal={handleOpenModal}
                />
              )}

              <div>
                {subPosts.map((post) => (
                  <SecondaryBlog
                    image={post.featured_image}
                    title={post.title}
                    author={`${post.author["first_name"]}  ${post.author["last_name"]}`}
                    date={post.date}
                    content={post.excerpt}
                    post={post}
                    handleOpenModal={handleOpenModal}
                  />
                ))}
              </div> */}
            </div>
            <h2 style={{ marginTop: "4vh" }}>New Blog Posts</h2>
            <Slider
              {...settings}
              //   style={{
              //     // display: "flex",
              //     flexDirection: "column",
              //     alignItems: "stretch",
              //   }}
            >
              {posts.map((post, index) => (
                <Blog
                  key={index}
                  image={post.featured_image}
                  title={post.title}
                  author={`${post.author["first_name"]}  ${post.author["last_name"]}`}
                  date={post.date}
                  content={post.excerpt}
                  post={post}
                  handleOpenModal={handleOpenModal}
                />
              ))}
            </Slider>
          </section>
        </div>
      </PageWrapper>
    </>
  );
};

export default BlogsLayout;
